// add critical document head code here
// import '@mapsight/core/env/browser';

// eslint-disable-next-line
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

if (isIE11) {
	const docEl = window.document.documentElement;
	docEl.classList.remove('no-ie');
	docEl.classList.add('ie');
}
